import React from "react"

interface Props {}

const defaultProps = {}

export const Footer: React.FC<Props> = () => {
  return (
    <footer>
      <div className="grid-container">
        <div className="grid-x grid-margin-x">
          <div className="cell">
          
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = defaultProps

export default Footer
