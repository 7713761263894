import React from "react"

import Transition from "../components/Layout/Transition"
import Header from "../components/Layout/Header"
import Footer from "../components/Layout/Footer"

interface Props {
  children: React.ReactNode
  location: Location
  pageContext?: any
}

const defaultProps = {
  pageContext: {},
}

const Layout = ({ children, location }: Props) => {
  return (
    <>
      {/* <Header /> */}

      <main>
        <Transition location={location}>{children}</Transition>
      </main>

      <Footer />
    </>
  )
}

Layout.defaultProps = defaultProps

export default Layout
